
import OrderCart from "./order-cart-v1"
import InputValidator from "./input-validator-v1"
import "./input-v1"
import "./address-v1"
import "./checkout-login-v1"
import { CF2Component } from 'javascript/lander/runtime'

export default class OrderFormV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    this.orderFormFields = ['email', 'first_name', 'last_name', 'phone_number'];
    const phoneInput = this.element.querySelector('[name="phone_number"]');
    this.addresses = this.getComponents("Address/V1")
    this.billingAddress = this.addresses.find((address) => address.type === 'billing')
    this.shippingAddress = this.addresses.find((address) => address.type === 'shipping')
    this.garlicValues = globalThis.CFGarlicValues ?? {}
    const validMsg = this.element.querySelector(
      '[data-input-status-type="valid"]'
    );
    const errorMsg = this.element.querySelector(
      '[data-input-status-type="error"]'
    );
    // This one exists only to use garlic
    const countryHiddenInput = this.element.querySelector(
      '[name="initial_country"]'
    );

    const spinner = this.element.querySelector('.elSpinnerWrapper')
    if (!window.ClickFunnels.all_countries) {
      window.addEventListener('CF2_COUNTRIES_FETCHED', () => {
        this.updateOrderSummaryDetails(false)
        delete spinner.dataset.loader
      })
    } else {
      this.updateOrderSummaryDetails(false)
      delete spinner.dataset.loader
    }

    if (countryHiddenInput && phoneInput) {
      const $countryHiddenInput = $(countryHiddenInput);
      if (phoneInput.value !== '' && countryHiddenInput.value === '') {
        countryHiddenInput.value = 'us';
        $countryHiddenInput.trigger("change");
      }
      // TODO: The intlTelInput setup should be handled by the Input/V1 blueprint
      const iti = window.intlTelInput(phoneInput, {
        initialCountry: countryHiddenInput.value,
        autoPlaceholder: "aggressive",
        utilsScript:
          "https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.16/build/js/utils.js",
        preferredCountries: ["us"],
      });

      phoneInput.iti = iti;

      if (this.labelType == 'inside' ) {
        // Adding additional classes to country code dropdown
        phoneInput.previousSibling.classList.add('elInput', 'inputHolder')
      }

      phoneInput.addEventListener("countrychange", () => {
        countryHiddenInput.value = phoneInput.iti.getSelectedCountryData().iso2;
        $countryHiddenInput.trigger("change");
        phoneInput.value = "";
        InputValidator.resetInputErrors(phoneInput)
      });

      const $parentPhoneInput = $(phoneInput).closest('.elFormItemWrapper')
      const inputFlag = this.element.querySelector('.iti__selected-flag')
      inputFlag?.addEventListener('focus', () => {
        $parentPhoneInput.addClass('elInputFocused')
      })
      inputFlag?.addEventListener('blur', () => {
        $parentPhoneInput.removeClass('elInputFocused')
      })

      // on blur: validate
      phoneInput.addEventListener("blur", () => {
        this.validatePhoneNumberInput()
      })

      // on keyup / change flag: reset
      phoneInput.addEventListener("change", () => InputValidator.resetInputErrors(phoneInput));
      phoneInput.addEventListener("keyup", () => InputValidator.resetInputErrors(phoneInput));
    }

    const $formInputs = $(
      this.element.querySelectorAll(".elInput, .elSelect, .elSuperSelect")
    );
    $formInputs.each(function () {
      const thisInput = $(this);
      thisInput.on("keypress", function (evt) {
        if (evt.key === "Enter") {
          document.dispatchEvent(new CustomEvent("OrderDetailsEnterPressed"));
        }
      });
    });

    window.addEventListener("payments:set-token-error", (evt) => this.addRebillyErrors(evt))

    this.orderFormFields.forEach((prop) => {
      const input = this.element.querySelector(`[name='${prop}']`)
      if(input !== null) {
        input.addEventListener('blur', () => {
          this.updateOrderSummaryDetails(true)
        })
        input.addEventListener('focus', () => {
          window.CheckoutV1ClearSubmitErrors()
        })
      }
    })

    const checkIfFilledWithGarlic = (val) => {
      return this.garlicValues[val]
    }

    if (this.orderFormFields.some(checkIfFilledWithGarlic)) {
      this.updateOrderSummaryDetails(true)
    }
  }

  getFormDetails() {
    return this.orderFormFields.reduce((acc, prop) => {
      const element = this.element.querySelector(`[name='${prop}']`)
      let value = element?.value
      if (value && element && prop == 'phone_number') {
        value = element.iti.getNumber()
      }
      acc[prop] = value
      return acc
    }, {})
  }

  updateOrderSummaryDetails(dispatchUpdate) {
    OrderCart.details = OrderCart?.details ?? {}
    OrderCart.details['customer'] = this.getFormDetails()
    if (dispatchUpdate) {
      let shouldUpdateOrderDetails = false
      const handleBillingAsShippingAddress = this.billingAddress?.billing_same_as_shipping_checkbox?.checked
      const isShippingFilled = this.shippingAddress?.filled(false)
      const isBillingFilled = this.billingAddress?.filled(false)

      if (handleBillingAsShippingAddress || !this.billingAddress?.enabled) {
        shouldUpdateOrderDetails = isShippingFilled
      } else if (!this.shippingAddress?.enabled) {
        shouldUpdateOrderDetails = isBillingFilled
      } else {
        shouldUpdateOrderDetails = isShippingFilled && isBillingFilled
      }

      OrderCart.details['billing_same_as_shipping'] = handleBillingAsShippingAddress
      OrderCart.dispatchOrderSummaryUpdate(shouldUpdateOrderDetails)
    }
  }

  validatePhoneNumberInput() {
    const phoneInput = this.element.querySelector('[name="phone_number"]');
    return InputValidator.resetErrorsAndCheckPhoneNumber(phoneInput)
  }

  addRebillyErrors(evt) {
    const errors = evt.detail?.error?.details
    errors.forEach((error) => {
      const input = this.element.querySelector(`input[data-rebilly="${error['data-rebilly']}"]`)
      if (input) {
        const inputWrapper = input.parentElement
        const borderHolder = $(inputWrapper).find('.inputHolder, .borderHolder')
        borderHolder.css('border-color', '')
        borderHolder.css('border-width', '')
        inputWrapper.classList.remove('elInputValid')
        inputWrapper.classList.add('elInputError')

        const status = input.parentElement.querySelector('[data-input-status-type]')
        $(status).text(error.error)
        status.setAttribute('data-input-status-type', 'error')
      }
    })
  }



}

window["OrderFormV1"] = OrderFormV1

