import '../dist/checkout-v1.css'
import '../blueprints/order-cart-v1.ts'
import '../blueprints/multiple-payments-v1.ts'
import '../blueprints/terms-of-service-v1.ts'
import '../blueprints/checkout-step-button-v1.ts'
import '../blueprints/order-summary-v1.ts'
import '../blueprints/order-summary-v2.ts'
import '../blueprints/product-item-v1.ts'
import '../blueprints/product-card-v1.ts'
import '../blueprints/product-list-v1.ts'
import '../blueprints/product-select-v1.ts'
import '../blueprints/address-v1.ts'
import '../blueprints/checkout-login-v1.ts'
import '../blueprints/order-form-v1.ts'
import '../blueprints/delivery-select-v1.ts'
import '../blueprints/multi-step-v1.ts'
import '../blueprints/checkout-v1.ts'
