
import OrderCart from "./order-cart-v1"
import { getSelectedVariant } from '@yggdrasil/Order/ProductSelect/utils'
import { CF2Component } from 'javascript/lander/runtime'

export default class ProductItemV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}


  selectedClass = 'elProductCardSelected';

  mount() {
    this.productCardComponent = this.getComponent('ProductCard/V1')
    this.inputName = this.productCardComponent.product_select_type == 'normal' ? 'product' : 'product_bump'
    this.cardEl = this.productCardComponent.element
    this.input = this.element.querySelector(`[name="${this.inputName}"]`);
    this.ctaCheckbox = this.element.querySelector('[name="cta-checkbox"]');
    this.product = this.productCardComponent.product

    this.valuesPositions = this.product.sorted_property_values?.reduce((acc, property) => {
      property.value_ids.forEach((value, index) => {
        acc[value] = index
      })
      return acc
    }, {}) ?? {}

    this.variantValues = Object.entries(this.product.property_values_variant_mapping ?? {}).reduce((acc, entries) => {
      const values = entries[0].split(',').map((value) => parseInt(value))
      acc[entries[1]] = values
      return acc
    }, {}) ?? {}

    this.element.addEventListener('click', (evt) => {
      if((this.selectStyle === "single" && !this.productCardComponent.isChecked) || this.selectStyle === "multiple") {
        this.productCardComponent.isChecked = !this.productCardComponent.isChecked
        this.productCardComponent.render()
        this.setupElements()
        this.registerEventListeners()
        this.selectProduct()
      }
      if(this.ctaCheckbox) {
        if(evt.target.nodeName !== "BUTTON" && evt.target !== this.ctaCheckbox && !this.ctaCheckbox.checked) {
          this.ctaCheckbox.click();
        }
      }

    })

    if(this.input.checked) {
      this.selectProduct();
    }

    this.registerEventListeners()
  }

  updateQuantity() {
    const quantity = parseInt(this.input.value);
    this.updateSelectedVariant(this.productCardComponent.variant, this.productCardComponent.selected_price, quantity);
    OrderCart.updateProductVariantQuantity(this.productCardComponent.variant.id, quantity);
    if(quantity > 0) {
      this.cardEl.classList.add(this.selectedClass);
      if (this.ctaCheckbox) {
        this.ctaCheckbox.checked = true;
      }
    } else {
      this.cardEl.classList.remove(this.selectedClass);
      if (this.ctaCheckbox) {
        this.ctaCheckbox.checked = false;
      }
    }
  }

  selectProduct() {
    if (this.selectStyle === "single") {
      $(this.element).parents('.elProductSelect')[0]
        .querySelectorAll('.' + this.selectedClass)
        .forEach((el)=> el.classList.remove(this.selectedClass));
      this.input.checked = true;
      this.cardEl.classList.add(this.selectedClass);
      this.updateSelectedVariant(this.productCardComponent.variant, this.productCardComponent.selected_price);
    } else if(this.selectStyle === "multiple") {
      if (this.input.checked) {
        this.cardEl.classList.add(this.selectedClass);
      } else {
        this.cardEl.classList.remove(this.selectedClass);
      }
      this.updateSelectedVariant(this.productCardComponent.variant, this.productCardComponent.selected_price);
    } 
  }

  updateSelectedVariant(newVariant, selectedPrice, quantity = undefined) {
    if (quantity == undefined)  {
      if (this.selectStyle === 'multiple') {
        quantity = Number(this.input.checked)
      } else if (this.selectStyle === 'single') {
        quantity = 1
      }
    }

    const variant = {
      id: newVariant.id,
      name: newVariant.name ?? this.product.default_variant.name,
      quantity: quantity,
      currency: newVariant.currency,
      image: newVariant.image ?? this.product.image,
      priceCents: selectedPrice.price_cents,
      priceId: selectedPrice.id,
      productType: newVariant.product_type ?? this.product.product_type,
    }

    this.subscribers['updateSelectedVariant']?.forEach((sub) => sub(variant));

    const prevVariantIndex = this.productCardComponent.variant;
    if(this.productCardComponent.variant?.id) {
      OrderCart.removeProductVariant(this.productCardComponent.variant.id);
    } 
    this.productCardComponent.variant = newVariant;
    OrderCart.updateProductVariant(
      {...variant, selected_price: 
        this.productCardComponent.selected_price, 
        priceId: this.productCardComponent.selected_price.id
      }
    );
  }

  unselectVariant(variantId = undefined) {
    if(OrderCart) {
      variantId = variantId ?? this.productCardComponent.variant.id
      OrderCart.removeProductVariant(variantId);
      this.productCardComponent.isChecked = false
      this.productCardComponent.render()
      this.setupElements()
      this.registerEventListeners()
    }
  }

  setupElements() {
    this.ctaCheckbox = this.element.querySelector('[name="cta-checkbox"]');
    this.input = this.element.querySelector(`[name="${this.inputName}"]`);
  }

  registerEventListeners() {
    this.productCardComponent.element.querySelectorAll('.elProductCardPriceSelect').forEach((select, index) => {
      select.addEventListener('click', (e) => {
        e.stopImmediatePropagation()
        e.stopPropagation()
      })
      select.addEventListener('change', (e) => {
        // e.stopImmediatePropagation()
        // e.stopPropagation()
        this.productCardComponent.selected_price = this.productCardComponent.variant.prices.find((price) => price.id == e.target.value)
        this.productCardComponent.isChecked = this.input.checked
        if (this.input.checked) {
          this.updateSelectedVariant(this.productCardComponent.variant, this.productCardComponent.selected_price)
        }
        this.productCardComponent.render()
        this.setupElements()
        this.registerEventListeners()
      })
    })
    const variantSelects = this.productCardComponent.element.querySelectorAll('.elProductVariantSelectorSelect')
    variantSelects.forEach((select, index) => {
      // Add listeners for tracking variant changes
      select.addEventListener('click', (e) => {
        e.stopImmediatePropagation()
        e.stopPropagation()
      })
      select.addEventListener('change', (e) => {
        // e.stopImmediatePropagation()
        // e.stopPropagation()
        const newValues = [...variantSelects].map((e) => e.value)
        const selectedVariantId = getSelectedVariant(this.product.sorted_property_values, 
          this.product.property_values_variant_mapping, 
          this.variantValues, this.valuesPositions, index, newValues
        )
        const variant = this.product.variants.find((v) => v.id == String(selectedVariantId))
        this.productCardComponent.selected_price = variant.prices[0]
        this.productCardComponent.isChecked = this.input.checked
        if (this.input.checked) {
          this.updateSelectedVariant(variant, this.productCardComponent.selected_price)
        } else {
          this.productCardComponent.variant = variant
        }
        this.productCardComponent.render()
        this.setupElements()
        this.registerEventListeners()
      })
    })

    if(this.selectStyle === "quantity") {
      const [minusButton, addButton] = this.element.querySelectorAll('.elProductCardQuantityContainer button');
      
      minusButton.addEventListener('click', (evt) => {
        this.input.stepDown(1);
        this.updateQuantity();
      });

      addButton.addEventListener('click', (evt) => {
        this.input.stepUp(1);
        this.updateQuantity();
      });

      this.input.addEventListener('change', () => { 
        this.updateQuantity()
      })
    } else if (this.selectStyle === "quantity-forced") {
      this.input.addEventListener('change', () => { 
        this.updateQuantity()
      })
    } 

    this.ctaCheckbox?.addEventListener('change', () => {
      let value = 0;
      if(this.selectStyle === "quantity") {
        value = 1;
      } else if (this.selectStyle === "quantity-forced"){
        value = this.input.options[1].value;
      }
      this.input.value = this.ctaCheckbox.checked ? value : 0;
      this.updateQuantity();
    })
  }

  range (start, stop, step) { 
    return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)
  }



}

window["ProductItemV1"] = ProductItemV1

