
import OrderCart from "./order-cart-v1"
import InputValidator from "./input-validator-v1"
import "./terms-of-service-v1"
import "./product-select-v1"
import "./order-form-v1"
import "./checkout-step-button-v1"
import "./multiple-payments-v1"
import "./order-summary-v2"
import "./multi-step-v1"
import { CF2Component } from 'javascript/lander/runtime'

export default class CheckoutV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    this.multiSteps = this.getComponent("MultiStep/V1");
    this.productSelect = this.getComponent("ProductSelect/V1");
    this.productItems = this.getComponents("ProductItem/V1");
    this.orderForm = this.getComponent("OrderForm/V1");
    this.checkoutLogin = this.getComponent("CheckoutLogin/V1");
    this.multiplePayment = this.getComponent("MultiplePayments/V1");
    this.listenOrderEvents()
    this.getShippingAndBilling()

    // Ensure disable all buttons when product change request is been processed
    const submitButton = this.element.querySelector('[href="#submit-form"]');

    document.addEventListener("OrderSummaryStarted", (details) => {
      submitButton.dataset.disabled = true;
    });

    document.addEventListener("OrderSummaryFinished", (details) => {
      if (
        !details.detail ||
        typeof details.detail != "object" ||
        details.detail.name != "AbortError"
      ) {
        delete submitButton.dataset.disabled;
      }
    });

    document.addEventListener("OrderSummaryRenderBefore", () => {
      this.showShippingDetails();
      OrderCart.details = OrderCart.details ?? {}
      OrderCart.details['shippingEnabled'] = this.shippingAddress?.enabled
      OrderCart.details['billingEnabled'] = !!this.billingAddress?.enabled
    });

    // Clear errors on product change
    this.productItems.forEach((productItem) => {
      productItem.on("selectVariant", () => {
        this.productSelect.clearError();
      });
    });

    // Next step selector
    if (this.multiSteps) {
      this.multiSteps.on("next", (step) => {
        if (this.numberOfSteps == 3) {
          if (step === 0) {
            this.productSelect.clearError();
            if (OrderCart.productVariants.length === 0) {
              this.productSelect.setError(
                "You should select at least one product"
              );
              return false;
            }
            return true;
          } else if (step === 1) {
            if (this.otpAuthenticated) return true;
            return this.handleOrderFormNextActions()
          }
        } else if (this.numberOfSteps == 2) {
          if (step === 0) {
            if (this.otpAuthenticated) return true;
            return this.handleOrderFormNextActions()
          } else if (step === 1) {
            
          }
        }
      })
    }

    // Submit Order Handler
    const submitFormButton = this.element.querySelector(
      '[href="#submit-form"]'
    );

    submitFormButton.addEventListener("click", (evt) => {
      const $button = $(submitFormButton)
      this.canSubmitForm().then((res) => {
      this.clearCheckoutSubmitErrors()
        if (res) {
          window.handleFormSubmit($button);
        }
      })
    })

    this.multiplePayment.checkPaypalReady = () => {
      this.prepareSubmitForm()
      return this.canSubmitForm()
    } 

    document.addEventListener("OrderDetailsEnterPressed", (evt) => {
      const nextStep = $(this.element).find(
        '[data-page-element="CheckoutStepButton/V1"]:visible a'
      );
      nextStep[0]?.click();
    });

    // NOTE: SMS logic
    const spinner = this.element.querySelector(
      ".elCheckoutContactForm .elSpinnerWrapper"
    );
    const $spinner = $(spinner);

    const $contactFormButtons = $(".elCheckoutContactForm .elBTN");
    const phone_number = this.orderForm.element.querySelector('[name="phone"]');
    phone_number?.addEventListener("CheckoutValidPhoneNumberBlur", function () {
      const phone_number_value = phone_number.iti.getNumber();
      const data = {
        phone: phone_number_value,
      };
      const params = new URLSearchParams(data);
      $spinner.show();
      fetch(`/cf_check_contact_exists.json?${params.toString()}`)
        .then((response) => response.json())
        .then((res) => {
          if (res.result) {
            // NOTE: Buttons are located in the checkout blueprint
            // and we can'te re-render it for the time being due to
            // their dynamic children
            $contactFormButtons.hide();
            $(this.orderForm.element).hide();

            // Restore checkout login state as rendering the order form re-renders it as well
            this.checkoutLogin.show_checkout_login = true;
            this.checkoutLogin.contact = res.contact;
            this.checkoutLogin.render();
            this.checkoutLogin.mount();
            $(this.checkoutLogin.element).show();
            this.checkoutLogin.element.querySelector("input").focus();
          }
          $spinner.hide();
        });
    });

    document.addEventListener("CheckoutLinkResendCode", (evt) => {
      const phone_number_value = phone_number.iti.getNumber();
      const data = {
        phone: phone_number_value,
      };
      const params = new URLSearchParams(data);
      fetch(`/cf_resend_code.json?${params.toString()}`)
        .then((response) => response.json())
        .then((res) => {
          this.checkoutLogin.resetMessages();
          if (res.result) {
            this.checkoutLogin.setValid("Code sent!");
          } else {
            this.checkoutLogin.setError("There was an error resending code");
          }
        });
    });

    document.addEventListener("CheckoutContinueAsGuest", (evt) => {
      $contactFormButtons.show();
      $(this.orderForm.element).show();
      $(this.checkoutLogin.element).hide();
      $spinner.hide();
      this.orderForm.element.querySelector('[name="email"]').focus();
      this.otpAuthenticated = false;
    });

    window.CheckoutV1ClearSubmitErrors = () => {
      this.clearCheckoutSubmitErrors();
    }

    // document.addEventListener("CheckoutPhoneNumberCompleted", (evt) => {
    //   const phone_number_value = phone_number.iti.getNumber();
    //   this.checkoutLogin.resetMessages();
    //   const data = {
    //     phone: phone_number_value,
    //     otp_code: evt.detail.otp_code,
    //   };
    //   const params = new URLSearchParams(data);
    //   $spinner.show();
    //   fetch(`/cf_validate_otp.json?${params.toString()}`, {
    //     credentials: "include",
    //   })
    //     .then((response) => response.json())
    //     .then((res) => {
    //       $spinner.hide();
    //       if (!res.result) {
    //         this.checkoutLogin.setError("Invalid code");
    //       } else {
    //         $(this.checkoutLogin.element).hide();
    //         $contactFormButtons.show();
    //         $(this.orderForm.element).show();
    //         const contact = res.contact;

    //         contact.payment_methods = contact.payment_methods ?? [];
    //         contact.addresses = contact.addresses ?? [];

    //         this.orderForm.checkout_state = "saved";
    //         this.orderForm.contact = contact;

    //         this.orderForm.render(true);

    //         // NOTE: (maybe TODO?) There is room for improvement here. What happens is that after
    //         // re-rendering this component, we lose existing references of old elements in the DOM.
    //         // So we need to refresh these values with the new values from the DOM. We can possibly
    //         // have some cached children components and refreshing them after render() is called.
    //         // Storing these references belonging to the object maybe shouldn't be used, and we use
    //         // references of the cached children which would get automatically refreshed after render()
    //         // is called. Refreshing could happen or render or in hydrateTree method.
    //         this.getShippingAndBilling();
    //         this.showShippingDetails();

    //         this.multiplePayment.contact = contact;
    //         // NOTE: Test hiding payment properly
    //         // this.multiplePayment.checkout_state = "saved";
    //         // this.multiplePayment.isCheckout = true;
    //         // this.multiplePayment.hide_payment = true;
    //         this.multiplePayment.funnel = {};
    //         this.multiplePayment.funnel.enabled_payments = this.multiplePayment.enabled_payments;
    //         this.multiplePayment.rebillyDestroyAllPayments();
    //         this.multiplePayment.render();
    //         // NOTE: (maybe TODO?) I think we can improve render(), to always call mount in the node
    //         // which called it (as we already call mount to nodes )
    //         // to the node which is calling it. I also see that we can have an onDestroy()
    //         // or onUnmount() method for CF2Components, you could then add your own methods to removeListeners
    //         // created in the mount() process.
    //         //
    //         // Probably we need to worry to events added to document, and not to nodes themselves,
    //         // but in order to avoid any memory leak we should take care of both
    //         // https://stackoverflow.com/a/6033907.
    //         //
    //         // I think we can track when an element is going to be removed by detecting existing nodes
    //         // in the DOM before setting innerHTML in render(), and calling a similar method as
    //         // hydrateTree, but instead of mounting we would unmount all existing elements.
    //         this.multiplePayment.mount();
    //         this.multiplePayment.setupPayment();

    //         const multiplePaymentElement = this.multiplePayment.element;
    //         const orderForm = this.element.querySelector(
    //           "[data-page-element='OrderForm/V1']"
    //         );
    //         multiplePaymentElement.parentNode.insertBefore(
    //           orderForm,
    //           multiplePaymentElement
    //         );

    //         this.otpAuthenticated = true;
    //         this.multiSteps.next();
    //       }
    //     });
    // });
  }

  collectLeads() {
    const details = this.orderForm.getFormDetails()
    fetch('/user_pages/api/contacts', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(details),
    })
  }

  handleOrderFormNextActions() {
    this.shippingAddress?.cleanErrors();
    this.billingAddress?.cleanErrors();
    if (
      this.checkValidInputs() &&
      this.checkTOS() &&
      this.checkAddressesFilled().every((r) => r.res)
    ) {
      const resValidateAddress = this.validateAddresses();
      if (resValidateAddress) {
        this.collectLeads()
      }
      return resValidateAddress;
    }
    return false
  }

  clearCheckoutSubmitErrors() {
    const checkoutSubmitErrors = this.element.querySelector('.checkout-submit-errors')
    checkoutSubmitErrors.classList.remove('is-active')
    checkoutSubmitErrors.innerHTML = ''
    window.restoreButtonState()
  }

  handleOrderSubmitErrors(message, clearError = true) {
    const auxFrame = this.element.querySelector('.multiple-payment-aux-frame')
    $(auxFrame).css('display', 'none')

    const checkoutSubmitErrors = this.element.querySelector('.checkout-submit-errors')
    checkoutSubmitErrors.classList.add('is-active')
    checkoutSubmitErrors.innerHTML = message ?? 'Something went wrong, please try again'
    $([document.documentElement, document.body]).animate({ scrollTop: $(checkoutSubmitErrors).offset().top - 50 }, 200)
    if (clearError) {
      setTimeout(() => {
        this.clearCheckoutSubmitErrors()
      }, 5000)
    }
  }

  listenOrderEvents() {
    window.addEventListener("checkout:order-submit-errors", (event) => {
      const error = event?.detail?.error
      if (error) {
        this.handleOrderSubmitErrors(error, false)
      } else {
        this.handleOrderSubmitErrors()
      }
    })

    window.addEventListener("message", (event) => {
      if (event.data.sender == "CfOrderStatus") {
        const orderDetails = event.data.details
        const redirectTo = this.getButtonRedirectTo()
        if (redirectTo) {
          orderDetails.redirect_to = redirectTo
        }
        fetch(window.location.href, {
          credentials: 'same-origin',
          method: 'post',
          body: JSON.stringify(orderDetails),
          headers: {
            'Content-Type': 'application/json',
            'X-CF2-POST-TYPE': 'submit',
          },
        }).then((response) => {
          if (orderDetails['orderResult'] == 'declined') {
            this.handleOrderSubmitErrors()
          } else {
            if (response.ok) {
              window.location.href = response.headers.get('Location')
            } else {
              response.json().then((r) => {
                this.handleOrderSubmitErrors(r.error)
              })
            }
          }
        })
      }
    });
  }

  getButtonRedirectTo() {
    return this.element.querySelector('[href="#submit-form"]')?.getAttribute('data-on-submit-go-to')
  }

  prepareSubmitForm() {
    const submitFormButton = this.element.querySelector(
      '[href="#submit-form"]'
    );
    window.setRedirectOverride($(submitFormButton))
  }

  canSubmitForm() {
    this.productSelect.clearError();
    if (OrderCart.productVariants.length === 0) {
      this.productSelect.setError(
        "You should select at least one product"
      )
      return Promise.resolve(false)
    }

    if(!this.checkValidInputs() || !this.checkTOS()) {
      this.scrollToErrors()
      return Promise.resolve(false)
    }

    window.setButtonSubmitText();

    if (this.otpAuthenticated) {
      // ** OTP authenticated form submission **
      return Promise.resolve(true)
    }

    if (this.multiSteps) {
      // ** Guest Flow Form Submission **
      this.getShippingAndBilling()
      const res = this.checkAddressesFilled()
      if (res.every(r => r.res)) {
        return Promise.resolve(true)
      } else {
        const error = res.find(r => !r.res)
        if (error.type == 'shipping') {
          window.restoreButtonState();
          if (this.numberOfSteps == 3) {
            this.multiSteps.curr(2);
          } else if (this.numberOfSteps == 2) {
            this.multiSteps.curr(1);
          }
          this.multiSteps.stepsState[1].state = "incomplete";
          this.multiSteps.stepsState[2].state = "incomplete";
          this.billingAddress?.cleanErrors();
          this.shippingAddress.cleanErrors();
          this.shippingAddress.addError(
            "A new physical product was added in your cart, so you need to fill shipping address information",
            "warning"
          );
        } 
      }
    } else {
      // ** Saved Flow Form Submission **
      if (this.checkAddressesFilled().every(r => r.res)) {
        return this.validateAddresses().then((res) => {
          if (res) {
            return true
          } else {
            window.restoreButtonState();
            this.scrollToErrors()
            return false
          }
        });
      } else {
        window.restoreButtonState();
        this.scrollToErrors()
      }
    }
    return Promise.resolve(false)
  }

  scrollToErrors() {
    const $inputsWithErrors = $(".elInputError", this.element);
    const $statusWithErrors = $(
      '[data-input-status-type="error"]:not(:empty)',
      this.element
    );
    if ($inputsWithErrors.length) {
      $inputsWithErrors.trigger("focus");
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $inputsWithErrors.offset().top - 50,
        },
        200
      );
    } else if ($statusWithErrors.length) {
      $statusWithErrors.trigger("focus");
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $statusWithErrors.offset().top - 50,
        },
        200
      );
    }
  }

  hasPhysicalProducts() {
    return OrderCart.productVariants.some(
      (p) => p.productType == "physical"
    );
  }

  checkAddressesFilled() {
    const results = [];
    if (
      this.shippingAddress.always_show_shipping_address ||
      this.hasPhysicalProducts()
    ) {
      results.push({ type: "shipping", res: this.shippingAddress.filled() });
    }
    if (this.billingAddress?.formEnabled) {
      results.push({ type: "billing", res: this.billingAddress.filled() });
    }
    return results;
  }

  validateAddresses() {
    this.getShippingAndBilling();
    const promises = [];
    if (
      !this.shippingAddress?.hasSavedAddress() &&
      this.shippingAddress?.formEnabled &&
      (this.shippingAddress.always_show_shipping_address ||
        this.hasPhysicalProducts())
    ) {
      promises.push(this.shippingAddress.validateAddress());
    }
    if (
      !this.billingAddress?.hasSavedAddress() &&
      this.billingAddress?.formEnabled
    ) {
      promises.push(this.billingAddress.validateAddress());
    }
    return new Promise(function (resolve) {
      Promise.all(promises).then((results) => {
        if (
          results.every((r) => r != "error") &&
          results.some((r) => r == "wait")
        ) {
          setTimeout(() => {
            resolve(true);
          }, 3000);
        } else {
          resolve(results.every((r) => r == "ok"));
        }
      });
    });
  }

  getShippingAndBilling() {
    this.addresses = this.getComponents("Address/V1");
    this.shippingAddress = this.addresses.find(
      (addr) => addr.type == "shipping"
    );
    this.billingAddress = this.addresses.find((addr) => addr.type == "billing");
  }

  showShippingDetails() {
    this.getShippingAndBilling();
    if (!this.shippingAddress) return;
    if (
      this.shippingAddress.always_show_shipping_address ||
      this.hasPhysicalProducts()
    ) {
      if (!this.shippingAddress.userShouldConfirmAddress && this.shippingAddress.enabled) return;
      this.shippingAddress.enabled = true;

      this.shippingAddress.renderAndMount();
      if (this.shippingAddress.userShouldConfirmAddress) {
        // NOTE: we must enforce displaying the form if it borrored the address from billing for example.
        $(this.orderForm.element).css('display', 'block')
        this.shippingAddress.userShouldConfirmAddress = false
      }
      if (this.billingAddress) {
        this.billingAddress.showBillingSameAsShipping = true;
        this.billingAddress.formEnabled = false;
        this.billingAddress.clearFormFields();
        this.billingAddress.renderAndMount();
      }
    } else {
      if (!(!this.billingAddress || this.billingAddress.userShouldConfirmAddress) && !this.shippingAddress.enabled) return;
      this.shippingAddress.enabled = false;
      this.shippingAddress.clearFormFields();
      this.shippingAddress.remove();
      if (this.billingAddress) {
        this.billingAddress.showBillingSameAsShipping = false;
        this.billingAddress.formEnabled = true;
        this.billingAddress.renderAndMount();
        if (this.billingAddress.userShouldConfirmAddress) {
          $(this.orderForm.element).css('display', 'block')
          this.billingAddress.userShouldConfirmAddress = false
        }
      }
    }
  }

  checkTOS() {
    const $termsOfService = $(
      '[data-page-element="TermsOfService/V1"]:visible',
      this.element
    );
    const $input = $termsOfService.find("input");
    if ($input.length && !$input[0].checked) {
      const $statusContainer = $termsOfService.find("[data-input-status-type]");
      $statusContainer[0].setAttribute("data-input-status-type", "error");
      $statusContainer[0].innerHTML = "You must agree to the terms of service";
      return false;
    }
    return true;
  }
  checkValidInputs() {
    $(this.element).closest(".elFormItemWrapper").removeClass("elInputError elInputWarning elInputValid");
    const requiredItems = this.element.querySelectorAll(".elFormItem");
    const results = [...requiredItems].map((element) => InputValidator.validateInput(element))
    return results.every((r) => !!r)
  }



}

window["CheckoutV1"] = CheckoutV1

